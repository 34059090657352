import { formatTime } from '../../helpers/utils';
import { Person, RiderAbsence } from '../../types/People';
import DeleteIcon from '@mui/icons-material/Delete';
import { Lesson } from '../../types/Lessons';
import { Autocomplete, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useState } from 'react';

const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function AbsentRiderRow(props: {
  riders: Person[];
  lessons: Lesson[];
  absence: RiderAbsence & { error: boolean };
  updateAbsence: Function;
}) {
  const [selectedRider, setSelectedRider] = useState<{
    id: string;
    name: string;
    firstName: string;
    lastName: string;
  } | null>(null);

  return (
    <Grid container spacing={2} marginTop='0.5em'>
      <Grid item xs={12} sm={3}>
        <Autocomplete
          disablePortal
          getOptionLabel={(option) => option.name}
          getOptionKey={(option) => option.id}
          value={selectedRider}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          options={props.riders.map((rider) => {
            return {
              id: rider.id,
              name: rider.firstName + ' ' + rider.lastName,
              firstName: rider.firstName,
              lastName: rider.lastName,
            };
          })}
          renderInput={(params) => <TextField {...params} label='Rider' />}
          onChange={(event, selectedOption) => {
            setSelectedRider(selectedOption);
            props.updateAbsence({
              ...props.absence,
              rider: selectedOption ? selectedOption.id : '',
              riderFirstName: selectedOption ? selectedOption.firstName : '',
              riderLastName: selectedOption ? selectedOption.lastName : '',
            });
          }}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label='Absent Date'
          required
          type='date'
          onChange={(event) => {
            props.updateAbsence({
              ...props.absence,
              absentDate: event.target.value,
            });
          }}
          value={props.absence.absentDate}
          name='absentDate'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          label='Make Up Class Date'
          type='date'
          onChange={(event) => {
            props.updateAbsence({
              ...props.absence,
              makeupDate: event.target.value,
              makeupLesson: '',
              makeupLessonTime: '',
            });
          }}
          value={props.absence.makeupDate}
          name='makeupDate'
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
          helperText='Add a makeup lesson date if they are making up the lesson.'
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          label='Lesson Time'
          select
          aria-label='Lesson Time'
          onChange={(event) => {
            const parts = event.target.value.split('@');

            props.updateAbsence({
              ...props.absence,
              makeupLesson: Number(parts[0]),
              makeupLessonTime: parts[1],
            });
          }}
          value={
            props.absence.makeupLesson && props.absence.makeupLessonTime
              ? `${props.absence.makeupLesson}@${props.absence.makeupLessonTime}`
              : ''
          }
          name='makeupLesson'
          disabled={!props.absence.makeupDate}
          helperText={
            !props.absence.makeupDate ? 'Select a make up date to select the lessons time.' : ''
          }
          fullWidth
        >
          {props.lessons
            .filter((l) => {
              if (!props.absence.makeupDate) {
                return false;
              }
              return daysOfWeek[new Date(props.absence.makeupDate).getUTCDay()] === l.lessonDay;
            })
            .map((lesson) => (
              <MenuItem
                key={`${lesson.id}-option`}
                value={`${lesson.id}@${formatTime(lesson.lessonTime)}`}
              >
                {lesson.lessonTime ? formatTime(lesson.lessonTime) : ''}
              </MenuItem>
            ))}
        </TextField>
      </Grid>
      <Grid item xs={12} sm={1} alignContent={'center'}>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            props.updateAbsence();
          }}
          fullWidth
        >
          <DeleteIcon style={{ width: '20', paddingBottom: '3.5px' }} />
        </Button>
      </Grid>
    </Grid>
  );
}

export default AbsentRiderRow;
