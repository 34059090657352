import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ErrorBanner from '../../components/ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import {
  FeedType,
  Horse,
  HorseFarrier,
  HorseFeed,
  HorseLameness,
  horseType,
  owners,
} from '../../types/Horses';
import AddFeedModal from '../../components/Modals/AddFeedModal';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ConfirmDeletionModal from '../../components/Modals/ConfirmDeletionModal';
import AddLamenessModal from '../../components/Modals/AddLamenessModal';
import UpdateLamenessModal from '../../components/Modals/UpdateLamenessModal';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import HorseHeader from '../../components/Horses/HorseHeader';
import HorseData from '../../components/Horses/HorseData';
import AddFarrierModal from '../../components/Modals/AddFarrierModal';

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center', // Align items vertically
  width: '100%',
  marginBottom: '16px',
};

const titleContainerStyle = {
  width: '100%',
  borderBottom: '1px solid #ccc', // Underline
  paddingBottom: '8px', // Padding bottom for spacing
  display: 'flex', // Make it a flex container
  justifyContent: 'space-between', // Ensure the title and button are spaced apart
  alignItems: 'center', // Align items vertically
};

const closeButtonStyle = {
  marginLeft: 'auto',
};

function ViewHorse() {
  const [errorMessage, setErrorMessage] = useState('');
  const [horse, setHorse] = useState<Horse>({
    barnName: '',
    birthDate: '',
    owner: '',
    type: 0,
    feed: [],
    lameness: [],
    hasPhoto: false,
    farrier: [],
  });
  const [showModal, setShowModal] = useState<'closed' | FeedType.Hay | 'other'>('closed');
  const [showLamenessModal, setShowLamenessModal] = useState<boolean>(false);
  const [showUpdateLamenessModal, setShowUpdateLamenessModal] = useState<boolean>(false);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [currentFeed, setCurrentFeed] = useState<HorseFeed | undefined>();
  const [currentLameness, setCurrentLameness] = useState<HorseLameness | undefined>();
  const [currentFarrier, setCurrentFarrier] = useState<HorseFarrier | undefined>();
  const [showFarrierModal, setShowFarrierModal] = useState<boolean>(false);

  const params = useParams();
  const horseId = params.horse!;

  async function getHorse(setHorse: Function) {
    try {
      const result = await HttpClient.get(`/horses/${horseId}`);
      setHorse(result);
    } catch (error) {
      setErrorMessage('Could not load horse. Please contact Rebecca.');
    }
  }

  async function handleDelete() {
    if (!currentFeed && !currentFarrier) {
      setErrorMessage('Error getting item to delete.');
      return;
    }

    console.log('deleting', currentFeed, currentFarrier);

    if (currentFeed) {
      try {
        const result = await HttpClient.delete(`/horses/${horseId}/feed/${currentFeed.id}`);
        setShowDelete(false);
        setCurrentFeed(undefined);
        await getHorse(setHorse);
      } catch (error) {
        setErrorMessage((error as Error).message);
      }

      return;
    } else if (currentFarrier) {
      try {
        const result = await HttpClient.delete(`/horses/${horseId}/farrier/${currentFarrier.id}`);
        setShowDelete(false);
        setCurrentFarrier(undefined);
        await getHorse(setHorse);
      } catch (error) {
        setErrorMessage((error as Error).message);
      }
    }
  }

  useEffect(() => {
    getHorse(setHorse);
  }, []);

  const lamenessColumns: GridColDef[] = [
    { field: 'startDate', headerName: 'Start Date', flex: 1, minWidth: 150 },
    { field: 'endDate', headerName: 'End Date', flex: 1, minWidth: 150 },
    { field: 'recheckDate', headerName: 'Check Date', flex: 1, minWidth: 150 },
    { field: 'affectedPart', headerName: 'AffectedPart', flex: 1, minWidth: 150 },
    { field: 'notes', headerName: 'Comments', flex: 2, minWidth: 200 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              setCurrentLameness(params.row);
              setShowUpdateLamenessModal(true);
            }}
          >
            Update
          </Button>
        </strong>
      ),
    },
  ];

  const feedColumns: GridColDef[] = [
    { field: 'feedTime', headerName: 'Feed Time', flex: 1, minWidth: 150 },
    { field: 'subType', headerName: 'Name', flex: 1, minWidth: 150 },
    { field: 'amount', headerName: 'Amount', flex: 1, minWidth: 150 },
    { field: 'details', headerName: 'Details', flex: 1, minWidth: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              setCurrentFeed(params.row);
              setShowModal('other');
            }}
            style={{ marginRight: '0.5em' }}
          >
            <EditIcon />
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              setCurrentFeed(params.row);
              setShowDelete(true);
            }}
            style={{ marginRight: '0.5em' }}
          >
            <DeleteIcon />
          </Button>
        </strong>
      ),
    },
  ];

  const farrierColumns: GridColDef[] = [
    { field: 'appointmentDate', headerName: 'Date', flex: 1, minWidth: 150 },
    { field: 'type', headerName: 'Type', flex: 1, minWidth: 150 },
    { field: 'notes', headerName: 'Notes', flex: 1, minWidth: 150 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <strong>
          <Button
            variant='contained'
            color='success'
            onClick={() => {
              setCurrentFarrier(params.row);
              setShowFarrierModal(true);
            }}
            style={{ marginRight: '0.5em' }}
          >
            <EditIcon />
          </Button>
          <Button
            variant='contained'
            color='error'
            onClick={() => {
              setCurrentFarrier(params.row);
              setShowDelete(true);
            }}
            style={{ marginRight: '0.5em' }}
          >
            <DeleteIcon />
          </Button>
        </strong>
      ),
    },
  ];

  return (
    <Container>
      <ErrorBanner errorHeading='Error getting horse' errorMessage={errorMessage} />
      {showModal !== 'closed' ? (
        <AddFeedModal
          type={showModal}
          horse={horseId}
          feed={currentFeed}
          show={true}
          handleClose={() => {
            setCurrentFeed(undefined);
            setShowModal('closed');
          }}
          handleConfirm={() => {
            setCurrentFeed(undefined);
            getHorse(setHorse);
          }}
        />
      ) : null}
      {showLamenessModal ? (
        <AddLamenessModal
          horse={horseId}
          horseName={horse.barnName}
          lameness={currentLameness}
          show={showLamenessModal}
          handleClose={() => {
            setCurrentLameness(undefined);
            setShowLamenessModal(false);
          }}
          handleConfirm={() => {
            setCurrentLameness(undefined);
            getHorse(setHorse);
          }}
        />
      ) : null}
      {showUpdateLamenessModal ? (
        <UpdateLamenessModal
          horse={horseId}
          horseName={horse.barnName}
          lameness={currentLameness!}
          show={showUpdateLamenessModal}
          handleClose={() => {
            setCurrentLameness(undefined);
            setShowUpdateLamenessModal(false);
          }}
          handleConfirm={() => {
            setCurrentLameness(undefined);
            getHorse(setHorse);
          }}
        />
      ) : null}
      {showFarrierModal ? (
        <AddFarrierModal
          horse={horseId}
          horseName={horse.barnName}
          farrier={currentFarrier}
          show={showFarrierModal}
          handleClose={() => {
            setCurrentFarrier(undefined);
            setShowFarrierModal(false);
          }}
          handleConfirm={() => {
            setCurrentFarrier(undefined);
            getHorse(setHorse);
          }}
        />
      ) : null}
      <ConfirmDeletionModal
        show={showDelete}
        handleClose={() => {
          setCurrentFarrier(undefined);
          setCurrentFeed(undefined);
          setShowDelete(false);
        }}
        handleConfirm={handleDelete}
      />
      <Box>
        <Grid item>
          <Card
            style={
              window.innerWidth > 640
                ? { display: 'flex', alignItems: 'center', marginBottom: '16px' }
                : {
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '16px',
                    flexDirection: 'column',
                  }
            }
          >
            <Avatar
              style={{ width: '15rem', height: '15rem', margin: '1rem' }}
              src={horse.hasPhoto ? `/images/${horse.barnName}.jpeg` : '/images/default.jpeg'}
            />
            <CardContent>
              <Typography gutterBottom variant='h5' component='div'>
                {horse.barnName}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                Birth Date: {horse.birthDate}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                Owner: {owners[horse.owner]}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                Arrived at WELCA: {horse.startDate}
              </Typography>
              <Typography variant='body2' color='text.secondary'>
                Type: {horseType[Number(horse.type)]}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <HorseHeader
          title='Lameness'
          onClick={() => {
            setShowLamenessModal(true);
          }}
        />
        {horse.lameness ? (
          <DataGrid
            rows={horse.lameness?.map((lameness) => {
              return {
                ...lameness,
                endDate: lameness.endDate ? lameness.endDate : 'Ongoing',
                checkDate: lameness.checkDate ? lameness.checkDate : 'N/A',
              };
            })}
            columns={lamenessColumns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 25 },
              },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            style={{ marginBottom: '1rem' }}
          />
        ) : (
          <Typography variant='body2' style={{ marginBottom: '1rem' }}>
            No lameness recorded
          </Typography>
        )}
        <Box>
          <HorseHeader
            title='Hay'
            onClick={() => {
              setCurrentFeed(horse.feed?.find((feed) => feed.feedType === FeedType.Hay));
              setShowModal(FeedType.Hay);
            }}
            edit={horse.feed?.find((feed) => feed.feedType === FeedType.Hay) != undefined}
          />
          <Typography variant='body2' style={{ marginBottom: '1rem' }}>
            {horse.feed?.find((feed) => feed.feedType === FeedType.Hay)
              ? horse.feed?.find((feed) => feed.feedType === FeedType.Hay)?.amount
              : 'No hay added'}
          </Typography>
        </Box>
        <Box>
          <HorseHeader
            title='Special Feed'
            onClick={() => {
              setCurrentFeed(undefined);
              setShowModal('other');
            }}
          />

          {horse.feed ? (
            <DataGrid
              rows={horse.feed
                ?.filter((feed) => {
                  return feed.feedType != FeedType.Hay;
                })
                .map((feed) => {
                  return {
                    ...feed,
                    subType: feed.subType ? feed.subType : feed.feedType,
                  };
                })}
              columns={feedColumns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
            />
          ) : (
            <Typography variant='body2' style={{ marginBottom: '1rem' }}>
              No feed present
            </Typography>
          )}
        </Box>
        <HorseData
          title='Farrier'
          onAddClick={() => {
            setCurrentFarrier(undefined);
            setShowFarrierModal(true);
          }}
          rows={horse.farrier}
          columns={farrierColumns}
          missingMessage='No farrier appointments recorded'
        />
      </Box>
    </Container>
  );
}

export default ViewHorse;
