import { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { Day } from './DayView';
import { formatTime } from '../../helpers/utils';
import { SubmitButton } from '../../components/SubmitButton';
import { Horse } from '../../types/Horses';
import { useLocation, useNavigate } from 'react-router-dom';
import { HttpClient } from '../../helpers/httpClient';
import KnowledgeableSelect from '../../components/KnowledgeableSelect';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CircularProgress from '@mui/material/CircularProgress';

const dayString = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

function UpdateBoard() {
  const location = useLocation();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState('');
  const [date, setDate] = useState<dayjs.Dayjs | null>(
    location?.state?.date ? location?.state?.date : dayjs()
  );
  const [boardData, setBoardData] = useState<Day>();
  const [horses, setHorses] = useState<Horse[]>([]);

  const [loadingBoard, setLoadingBoard] = useState<boolean>(true);
  const [loadingHorses, setLoadingHorses] = useState<boolean>(true);

  async function handleHorseChange(
    selectedOption: Horse | undefined,
    riderId: string,
    lessonId: string
  ) {
    try {
      const result = await HttpClient.put(
        `/riders/${riderId}/lessons/${lessonId}/date/${date?.format('YYYY-MM-DD')}/horse`,
        {
          horseId: selectedOption?.id,
          horseName: selectedOption?.barnName,
        }
      );
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getBoard() {
    setLoadingBoard(true);
    if (!date) {
      return;
    }

    try {
      const result: Day = await HttpClient.get(
        `/board/day/${dayString[date.day()]}`,
        {},
        { date: date.format('YYYY-MM-DD') }
      );
      setBoardData(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoadingBoard(false);
  }

  async function getHorses() {
    setLoadingHorses(true);
    try {
      const result: Horse[] = await HttpClient.get('/horses');
      setHorses(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
    setLoadingHorses(false);
  }

  useEffect(() => {
    getBoard();
    getHorses();
  }, [date]);

  if (loadingBoard || loadingHorses) {
    return (
      <Box
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box margin={'1em'}>
      <Grid
        container
        spacing={2}
        paddingBottom='1em'
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={12}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              value={date}
              onChange={(value, context) => {
                setDate(value);
                navigate(`/board/${value?.format('YYYY-MM-DD')}/change`);
              }}
              slotProps={{ textField: { fullWidth: true } }}
            />
          </LocalizationProvider>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        paddingBottom='1em'
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Grid item xs={12} sm={12}>
          <Button
            fullWidth
            onClick={() =>
              navigate(
                {
                  pathname: `/board/${date?.format('YYYY-MM-DD')}/change/rider`,
                },
                {
                  state: {
                    date: date?.format('YYYY-MM-DD'),
                  },
                }
              )
            }
            variant='contained'
            color='success'
          >
            Add Rider
          </Button>
        </Grid>
      </Grid>

      <Box padding={'1em'}>
        {boardData
          ? boardData.map((lessonTime, index) => (
              <Box key={index}>
                <Typography
                  variant='h6'
                  sx={{
                    fontWeight: 'bold',
                  }}
                >
                  {formatTime(lessonTime.lessonTime)}
                </Typography>

                <Grid
                  container
                  item
                  columnSpacing={2}
                  paddingTop='0.5em'
                  paddingBottom='0.5em'
                  marginLeft='-0.5em'
                >
                  <Grid item xs={5} sx={{ fontWeight: 'bold' }}>
                    Name
                  </Grid>
                  <Grid item xs={7} sx={{ fontWeight: 'bold' }}>
                    Horse
                  </Grid>
                </Grid>

                {lessonTime.entries.map((rider, index) => {
                  return (
                    <Grid
                      item
                      container
                      key={index}
                      sx={{
                        backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'white',
                      }}
                      alignItems='center'
                      justifyContent='center'
                      columnSpacing={2}
                      paddingTop='1em'
                      paddingBottom='1em'
                      marginLeft='-0.5em'
                    >
                      <Grid item xs={5}>
                        {rider.personName}
                      </Grid>
                      <Grid item xs={7}>
                        <KnowledgeableSelect
                          label='Horse'
                          options={
                            horses?.map((horse) => {
                              return {
                                display: horse.barnName,
                                otherDetails: horse,
                              };
                            })!
                          }
                          initialSelected={
                            rider.horseId
                              ? horses?.findIndex((obj) => obj.id === rider.horseId)!
                              : -1
                          }
                          defaultOption={{
                            id: '',
                            barnName: '',
                            birthDate: '',
                            owner: '',
                            type: '',
                            hasPhoto: false,
                          }}
                          onChange={(selectedOption: Horse | undefined) => {
                            handleHorseChange(
                              selectedOption,
                              rider.personId,
                              lessonTime.lessonId.toString()
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  );
                })}
              </Box>
            ))
          : null}
      </Box>
      <SubmitButton
        handleSubmit={async () => {
          navigate(`/board/${date?.format('YYYY-MM-DD')}`);
        }}
        disabled={false}
        label='Back'
      />
    </Box>
  );
}

export default UpdateBoard;
