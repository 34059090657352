import { MenuItem, TextField } from '@mui/material';
import { useState } from 'react';

function KnowledgeableSelect<T>(props: {
  id?: string;
  options: { display: string; otherDetails: T }[];
  onChange: (selectedOption: T | undefined) => void;
  required?: boolean;
  disabled?: boolean;
  defaultOption?: T;
  missingRelatedFieldText?: string;
  label: string;
  initialSelected: number;
}) {
  const [selectedIndex, setSelectedIndex] = useState<number>(props.initialSelected);

  return (
    <TextField
      id={props.id}
      required
      label={props.label}
      select
      aria-label={props.label}
      onChange={(e) => {
        setSelectedIndex(Number(e.target.value));
        if (e.target.value === '-1') {
          props.onChange(props.defaultOption);
          return;
        }

        props.onChange(props.options[Number(e.target.value)]?.otherDetails);
      }}
      value={selectedIndex !== undefined ? selectedIndex : ''}
      disabled={props.disabled}
      helperText={
        props.missingRelatedFieldText && props.disabled ? props.missingRelatedFieldText : ''
      }
      fullWidth
    >
      {props.defaultOption ? (
        <MenuItem value='-1'>
          <em>None</em>
        </MenuItem>
      ) : null}
      {props.options
        ? props.options.map((option, index) => (
            <MenuItem value={index} key={index}>
              {option.display}
            </MenuItem>
          ))
        : null}
    </TextField>
  );
}

export default KnowledgeableSelect;
