import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ErrorBanner from '../../components/ErrorBanner';
import { HttpClient } from '../../helpers/httpClient';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Horse, HorseSaddle, Pad, Saddle } from '../../types/Horses';
import { v4 as uuidv4 } from 'uuid';

import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import HorseSaddleComponent from '../../components/Horses/HorseSaddleComponent';

function EditHorseSaddle() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [horse, setHorse] = useState<Horse>({
    barnName: '',
    birthDate: '',
    owner: '',
    type: 0,
    feed: [],
    lameness: [],
    hasPhoto: false,
    farrier: [],
  });
  const [saddleList, setSaddleList] = useState<Saddle[]>([]);
  const [saddles, setSaddles] = useState(horse.saddles ?? []);
  const [fetchedHorseSaddles, setFetchedHorseSaddles] = useState(false);
  const params = useParams();
  const horseId = params.horse!;

  async function getHorse() {
    try {
      const result: Horse = await HttpClient.get(`/horses/${horseId}`);
      setHorse(result);
    } catch (error) {
      setErrorMessage('Could not load horse. Please contact Rebecca.');
    }
  }

  async function getHorseSaddles() {
    try {
      const result: HorseSaddle[] = await HttpClient.get(`/horses/${horseId}/saddles`);
      setSaddles(
        result.map((r) => {
          return { ...r, padsArray: r.pads.length === 0 ? [] : (r.pads.split(', ') as Pad[]) };
        })
      );
      setFetchedHorseSaddles(true);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  async function getSaddles() {
    try {
      const result: Saddle[] = await HttpClient.get(`saddles`);
      setSaddleList(result);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }
  }

  const onDragEnd = (result: any) => {
    const { source, destination } = result;

    // If no destination (dropped outside list) or dropped in the same place
    if (!destination || source.index === destination.index) {
      return;
    }

    // Reordering the items
    const reorderedItems = Array.from(saddles);
    const [movedItem] = reorderedItems.splice(source.index, 1);
    reorderedItems.splice(destination.index, 0, movedItem);

    setSaddles(reorderedItems);
  };

  const addSaddle = () => {
    setSaddles([
      ...saddles,
      {
        id: uuidv4(),
        saddle: '',
        saddleId: '',
        additions: '',
        rank: 0,
        padsArray: [],
        pads: '',
        notes: '',
        horse: horseId,
        priorityFit: false,
        smallSaddle: false,
      },
    ]);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const rankedSaddles = saddles.map((saddle, index) => {
      return { ...saddle, rank: index, pads: saddle.padsArray.join(', ') };
    });

    try {
      const result = await HttpClient.post(`/horses/${horse.id}/saddles`, rankedSaddles);
      navigate(`/horses/saddles`);
    } catch (error) {
      setErrorMessage((error as Error).message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getHorse();
    getHorseSaddles();
    getSaddles();
  }, [horseId]);

  if (!horse.id || saddleList.length <= 0 || !fetchedHorseSaddles) {
    return (
      <Container style={{ paddingTop: '1em' }}>
        <Grid item>
          <Typography variant='h3' gutterBottom align='center'>
            Loading Saddles...
          </Typography>
        </Grid>
      </Container>
    );
  }

  return (
    <Container style={{ paddingTop: '1em' }}>
      <Grid item>
        <Typography variant='h3' gutterBottom align='center'>
          Saddles for {horse.barnName}
        </Typography>
      </Grid>
      <ErrorBanner errorHeading='Error adding saddles' errorMessage={errorMessage} />
      <Box>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided) => (
              <List
                ref={provided.innerRef}
                style={{ margin: 'auto', marginTop: 50, border: 'none' }}
              >
                {saddles.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <ListItemButton
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                          margin: 8,
                          border: '2px solid #ddd',
                          borderRadius: '4px',
                        }}
                      >
                        <Grid container direction='column'>
                          <Grid item>
                            <HorseSaddleComponent
                              saddleList={saddleList}
                              saddle={item}
                              index={index}
                              onSaddleChange={(saddle: HorseSaddle) => {
                                const currentSaddles = [...saddles];
                                currentSaddles[index] = saddle;
                                setSaddles(currentSaddles);
                              }}
                            />
                          </Grid>
                          <Grid item paddingTop={'0.5em'}>
                            <Button
                              variant='contained'
                              color='error'
                              onClick={() => {
                                const newSaddles = [...saddles];
                                newSaddles.splice(index, 1);
                                setSaddles(newSaddles);
                              }}
                              style={{ marginRight: '0.5em' }}
                              fullWidth
                            >
                              Remove Saddle
                            </Button>
                          </Grid>
                        </Grid>
                      </ListItemButton>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <Grid item xs={12} sm={4}>
          <Button
            variant='contained'
            color='success'
            onClick={addSaddle}
            style={{ marginRight: '0.5em' }}
            fullWidth
            disabled={loading}
          >
            Add Saddle
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} paddingTop={'1em'} paddingBottom={'1em'}>
          <Button
            variant='contained'
            color='success'
            onClick={handleSubmit}
            style={{ marginRight: '0.5em' }}
            fullWidth
            disabled={loading}
          >
            Submit
          </Button>
        </Grid>
      </Box>
    </Container>
  );
}

export default EditHorseSaddle;
